import React, { useCallback } from 'react';
import { Input, Button, Form } from 'antd';
import QueueAnim from 'rc-queue-anim';
import SEO from 'components/SEO';
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';
import { useSelector, useDispatch } from 'react-redux';
import { verifyPasswordLoadingSelector } from 'redux/Auth/selector';
import { verifyPassword } from 'redux/Auth/slice';
import AuthLayout from 'components/Layout/AuthLayout';

function VerifyPasswordPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(verifyPasswordLoadingSelector);
  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    (values) => {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const email = params.get('email');
      dispatch(verifyPassword({ ...values, email: decodeURIComponent(email) }));
    },
    [dispatch]
  );

  const validatePasswordRegex = (_, value) => {
    if (!/^[\S]+.*[\S]+$/g.test(value)) {
      return Promise.reject(new Error(t('passwordRegex')));
    }
    return Promise.resolve();
  };

  return (
    <AuthLayout>
      <SEO title={t('verifyPassword')} description={t('SEODescription')} />
      <QueueAnim
        className="auth-banner-content-wrapper"
        delay={300}
        ease="easeOutQuart"
      >
        <h1 key="1">{startCase(t('verifyPassword'))}</h1>
        <h3 key="2">{t('verifyPasswordDesc1')}</h3>
        <p key="3">{t('verifyPasswordDesc2')}</p>
        <Form key="4" onFinish={handleSubmit} className="auth-form" form={form}>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: t('requiredCode'),
              },
            ]}
          >
            <Input addonBefore={t('verificationCode')} placeholder="123456" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: t('requiredPassword'),
              },
              {
                min: 8,
                message: t('minPassword'),
              },
              {
                max: 35,
                message: t('maxPassword'),
              },
              {
                validator: validatePasswordRegex,
              },
            ]}
          >
            <Input.Password
              addonBefore={t('newPassword')}
              placeholder="********"
            />
          </Form.Item>
          <Form.Item
            name="confirmNewPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
                message: t('requiredConfirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('inconsistentPassword')));
                },
              }),
            ]}
          >
            <Input.Password
              addonBefore={t('confirmPassword')}
              placeholder="********"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              size="large"
              loading={loading}
            >
              {t('send')}
            </Button>
            {t('alreadyAccount')}
            <Link to="/auth/sign-in">{t('signIn')}</Link>
          </Form.Item>
        </Form>
      </QueueAnim>
    </AuthLayout>
  );
}

export default React.memo(VerifyPasswordPage);
